import axios from "axios"
import { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { frontEnd_API, header, token } from "../Config/Config"
import { useDispatch, useSelector } from "react-redux"
import { updateUser } from '../Store/Slices/UserSlice';
import { useLocation } from "react-router-dom"

const SellerSubscriptionPlanDetail = () => {

    const dispatch = useDispatch()
    const location = useLocation()

    const sellerDetail = useSelector((state) => state?.user?.value)
    const [planDetail, setPlanDetail] = useState({})

    const getPlanDetail = async () => {
        const data = await axios?.get(frontEnd_API?.currentPlanDetail, header)
        setPlanDetail({ ...data?.data?.data })
        await dispatch(updateUser({ ...data?.data?.data }))
    }
    
    useEffect(() => {
        if (sellerDetail?.sellerSubscriptionId > 0 && token) {
            getPlanDetail()
        }
    }, [])

    return (
        <>
            {
                (planDetail?.sellerSubscriptionId > 0 && planDetail?.leftDays <= 3) && (
                    <div className="py-4">
                        <div className={(location.pathname.startsWith("/orders") || location.pathname.startsWith("/returns")) ? "container-fluid px-2 px-sm-3 px-md-5" : "container"}>
                            <Row>
                                <Col>
                                    <div className="bg-danger text-white py-2 px-3 fs-14 fw-semibold first-capitalize rounded">
                                    {
                                        planDetail?.leftDays > 1 ? (<>Your plan is about to expire. Only {planDetail?.leftDays} {planDetail?.leftDays > 1 ? 'days' : 'day'} left to renew!</>) : (<>Your plan has expired. Please renew to continue enjoying our services.</>)
                                    }   
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default SellerSubscriptionPlanDetail