import React, { useEffect, useState } from 'react'
import { Container, Nav, NavItem, NavLink, TabContainer } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom';
import ReturnOrderList from './ReturnOrderList';
import RtoOrderList from './RtoOrderList';
import RtsOrderList from './RtsOrderList';

function SellerReturns() {

    const navigate = useNavigate()
    const params = useParams();
    
    // state and define -----
    const ParentStatusData = [
        {
            label: 'Return',
            value: 'return',
        },
        {
            label: 'RTO',
            value: 'rto',
        },
        {
            label: 'RTS',
            value: 'rts',
        }
    ]
    const [groupObj, setGroupObj] = useState(params?? {status:'return', childStatus:'all'})
    // -----

    useEffect(() => {
        setGroupObj({...params})
    }, [params])   

    return (
        <div className='seller-return-order p-0' style={{ minHeight: "100vh" }}>
            <Container fluid className='seller-return-order-main-container pb-5 px-2 px-sm-3 px-md-5'>
                <div className=' d-grid d-sm-flex justify-content-sm-between gap-3 py-4'>
                    <h3 className='fw-semibold  m-0'>Manage Return Orders</h3>
                </div>
                <div className='seller-return-order-container p-2 p-sm-3'>
                    <TabContainer activeKey={groupObj?.status}>
                        <Nav fill className='custom-tabs mb-2' variant="pills">
                        {
                            ParentStatusData.map((row, index) => (
                                <NavItem key={index}>
                                    <NavLink
                                        active={row?.value == groupObj?.status}
                                        eventKey={row?.value}
                                        className="flex-center-align gap-1"
                                        onClick={() => navigate(`/returns/${row?.value}/all`)}
                                    >
                                        <span className='text-capitalize'>{row?.label}</span>
                                    </NavLink>
                                </NavItem>
                            ))
                        }
                        </Nav>
                        <div>
                        {
                            groupObj?.status == 'return' && (<ReturnOrderList status={groupObj?.status} childStatus={groupObj?.childStatus} />)
                        }
                        {
                            groupObj?.status == 'rto' && (<RtoOrderList status={groupObj?.status} orderStatus={groupObj?.childStatus} />)
                        }
                        {
                            groupObj?.status == 'rts' && (<RtsOrderList status={groupObj?.status} orderStatus={groupObj?.childStatus} />)
                        }
                        </div>
                    </TabContainer>
                </div>
            </Container>
        </div>
    )
}

export default SellerReturns