import axios from "axios";
import React, { useEffect, useState } from "react";
import { frontEnd_API, header, storage } from "./Config/Config";
import { Button } from "react-bootstrap";
import { useSelector } from "react-redux";

const BlockPopUp = () => {
    const user = useSelector((state) => state.user.value);
    const [showPopup, setShowPopup] = useState(false);
    const [waitlistCount, setWaitlistCount] = useState(0); // Initialize as 0
    const [sellerStatus, setSellerStatus] = useState(-1);

    // Function to get the seller status
    const getSellerStatus = async () => {
        try {
            const { data } = await axios.put(`${frontEnd_API?.status}`, { verifyStatus: 1 }, header);
            setSellerStatus(data?.data?.status ?? -1);
        } catch (error) {
            console.error("Error fetching seller status:", error);
        }
    };

    useEffect(() => {
        getSellerStatus();

        // Generate a random number between 1 and 10
        const randomAddition = Math.floor(Math.random() * 10) + 1;

        if (!user?.counter) {
            // Calculate the waitlist count based on user.id and add the random number
            const calculatedCount =
                user?.id === 0
                    ? 125 // Default to 125 if user.id is 0
                    : user?.id
                    ? Math.floor(user.id / 10) * 10 + 100 + 5
                    : 125; // Fallback to 125 if user.id is undefined

            // Set the calculated waitlist count
            localStorage?.setItem(storage?.user, JSON.stringify({
                ...user,
                counter:calculatedCount
            }))
            setWaitlistCount(calculatedCount);
        }
        else {
            setWaitlistCount(user?.counter)
        }

        // Show the popup
        setShowPopup(true);

        // Prevent body scrolling when popup is visible
        document.body.style.overflow = "hidden";

        // Cleanup on component unmount
        return () => {
            document.body.style.overflow = "auto";
        };
    }, [user?.id]);

    return (
        <div className="block-box">
            <div id="waitlist-popup" className={`popup ${showPopup ? "show" : ""}`}>
                <div className="popup-content">
                    <h2>You're on the Waitlist!</h2>
                    <p>
                        Thanks for signing up! You're now part of our exclusive waitlist.
                        We are reviewing sign-ups and will reach out to you soon.
                    </p>
                    <div className="waitlist-info">
                        <span>Waitlist Count: </span>
                        <span id="waitlist-count">{user?.tempCode}</span>
                    </div>
                    <p>We'll notify you when it's your turn!</p>
                    {sellerStatus === 1 && (
                        <Button
                            color="transparent"
                            className="unblock-btn border-2 border-white text-white fw-semibold"
                            onClick={() => {
                                const data = { ...user, status: 1 };
                                sessionStorage?.setItem(storage?.user, JSON.stringify(data));
                                setShowPopup(false); // Close popup instead of reloading
                            }}
                        >
                            Close
                        </Button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default BlockPopUp;
