import * as fabric from 'fabric'; // v6
import { useState, useRef, useEffect } from 'react';
import { SERVER_URL } from '../../../Config/Config';
import { MdOutlineZoomOutMap } from "react-icons/md";
import { Col, Container, Modal, Row, Spinner } from 'react-bootstrap';
import { Tooltip } from 'react-tooltip';
import ProductPreviewImage from './ProductPreviewImageComponent';

function EditCanvasComponent(props = {}) {

    const parentRef = useRef(null);
    const [show, setShow] = useState(false);
    const canvaRef = useRef(null);
    const [canvas, setCanvas] = useState(null);
    const [canvasData, setCanvasData] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentObject, setCurrentObject] = useState({})
    const [previewData, setPreviewData] = useState()
    const [colorVariantData, setColorVariantData] = useState();
    const [currentMockupObj, setCurrentMockupObj] = useState();
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        fabric.Object.prototype.toObject = (function (toObject) {
            return function (properties) {
                return Object.assign(toObject.call(this, properties), {
                    id: this.id // Include the `id` property
                });
            };
        })(fabric.Object.prototype.toObject);

        const fabricCanvas = new fabric.Canvas(canvaRef.current);
        setCanvas(fabricCanvas);
        return () => { fabricCanvas.dispose(); };
    }, []);

    useEffect(() => {
        const handleBeforeUnload = (event) => {
            event.preventDefault();
            event.returnValue = ''; // This line is required for some browsers.
            return 'Changes you made may not be saved.'; // Message will show on older browsers (like IE).
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, []);

    useEffect(() => {
        setCurrentIndex(props?.currentIndex)
        // console.log("props?.canvasData::", props?.canvasData);

        if (canvasData?.length == 0) {
            setCanvasData(props?.canvasData)
            console.log("props?.canvasData::", props?.canvasData);
        }

        setCurrentObject(props?.canvasData[props?.currentIndex])
        setPreviewData(props?.previewData)
        const previewObject = props?.previewData?.product?.previewImagesData?.length > 0 ? props?.previewData?.product?.previewImagesData[0] : '';
        const colorData = props?.previewData?.provider?.availableVariantData?.find(e => ['color', 'colors', 'colour', 'colours']?.includes(e?.name))?.data?.map(x => ({
            label: x?.label,
            code: x?.code,
            ...previewObject
        }))
        if (colorData?.length > 0) {
            setColorVariantData(colorData)
        }
        manageMockupDetail(colorData?.length > 0 ? colorData[0] : {})
    }, [props?.currentIndex, props?.canvasData, props?.previewData])

    useEffect(() => {
        setTimeout(() => {
            if (canvas) {
                const widthP = parentRef.current?.clientWidth;
                const heightP = parentRef.current?.clientHeight;
                canvas.setWidth(widthP);
                canvas.setHeight(heightP);
                canvas.renderAll();
            }
        }, 500)
    }, [currentIndex, canvasData]);

    useEffect(() => {
        // Check if `editedCanvasData` has data to load into the canvas
        if (props?.editedCanvasData && props?.editedCanvasData.length > 0) {
            // Extract the JSON data from the `editedCanvasData`
            const customCanvasData = props?.editedCanvasData[0]?.data?.objects;

            if (customCanvasData) {
                const jsonString = JSON.stringify({ objects: customCanvasData });

                // Load the custom JSON data into the canvas
                canvas?.loadFromJSON(jsonString, () => {
                    canvas.renderAll(); // Render the canvas after loading
                    updateCanvasData();  // Optionally update canvas data after load
                }, (object, error) => {
                    if (error) {
                        console.error('Error loading custom object:', error);
                    } else {
                        console.log('Custom JSON loaded:', object);
                    }
                });
            }
        }
    }, [props?.editedCanvasData, canvas]);

    useEffect(() => {
        if (canvas) {
            loadCanvas(currentIndex);
        }
    }, [canvas, currentIndex]);

    const loadCanvas = () => {
        const selectedCanvas = canvasData[currentIndex];
        if (selectedCanvas) {
            if (selectedCanvas?.data) {
                canvas.loadFromJSON(selectedCanvas.data, () => {
                    canvas.renderAll();
                    canvas.calcOffset();
                });
            } else {
                canvas.clear();
            }
            canvas.setWidth(selectedCanvas.width);
            canvas.setHeight(selectedCanvas.height);
            canvas.renderAll();
        }
    };

    const switchCanvas = ({ current = -1, next = -1 }) => {
        console.log("From Switch Canvas::", canvasData);
        console.log("current && next::", current, next);
        if (current >= 0 && next >= 0) {
            saveCanvasData(current);
            setCurrentIndex(next);
            setCurrentObject({ ...canvasData[next] })
            reloadElements(next);
            setLoader(true);
            setTimeout(() => { reloadElements(next) }, 750);
            setTimeout(() => { setLoader(false) }, 750);
        }
    };

    const previewChange = () => {
        saveCanvasData(currentIndex);
    }

    const reloadElements = (next) => {
        if (canvas) {
            // canvas.add(new fabric.Textbox('.', { top: 0, left: 0, width: 1, fontSize: 1 }));
            canvas.renderAll()
            console.log("canvasData before reload::", canvasData);
            props?.onSwitch({
                data: canvasData,
                activeCanvas: canvasData[next],
                index: next
            })

        }
    };

    useEffect(() => {
        console.log("props?.canvasValue::", props?.canvasValue);
        addElementFromCanvas();
        updateCanvasData();
    }, [props?.canvasValue])

    useEffect(() => {
        if (props?.newCanvasData !== null) {
            addElementFromCanvas(props?.newCanvasData)
        }
    }, [props?.newCanvasData])

    const addElementFromCanvas = (data) => {
        if (!canvas) return; // Ensure canvas is initialized

        const tempTextValue = (data) ? data : (props?.canvasValue?.length > 0) ? props?.canvasValue : [props?.canvasValue];

        if (data) {
            console.log("Update tempTextValue::", tempTextValue);
            const objects = canvas.getObjects();
            objects.forEach((obj) => {
                canvas.remove(obj);
            });
            canvas.renderAll();

            const jsonString = JSON.stringify({ objects: tempTextValue });

            // Load the JSON data into the canvas
            canvas.loadFromJSON(jsonString, () => {
                canvas.renderAll();
                saveCanvasData(currentIndex);
            }, (object, error) => {
                if (error) {
                    console.error('Error loading object:', error);
                } else {
                    console.log('Object loaded:', object);
                }
            });
        }
        else {
            console.log("Else tempTextValue::", tempTextValue);
            tempTextValue.forEach((tempTextValue) => {
                if (["text", "textbox", "clipart"].includes(tempTextValue?.type.toLowerCase())) {
                    const check = canvasData[currentIndex]?.data?.objects?.find((state) => state?.id === tempTextValue?.id);
                    let textDataSend = "";
                    if (check) {
                        textDataSend = canvas.getObjects().find((obj) => obj?.id === tempTextValue?.id);

                        if (textDataSend) {
                            // Update the properties of the existing textbox
                            console.log("textDataSend::", textDataSend);

                            textDataSend.set({
                                text: tempTextValue?.data,
                                fontSize: tempTextValue?.style?.fontSize ? (tempTextValue?.style?.fontSize / textDataSend.scaleX) : 16,
                                fontWeight: tempTextValue?.style?.fontWeight ?? "normal",
                                textAlign: tempTextValue?.style?.textAlign ?? "left",
                                fontStyle: tempTextValue?.style?.fontStyle ?? "normal",
                                underline: (tempTextValue?.style?.textDecoration == "underline") ? true : false,
                                fill: tempTextValue?.style?.color ?? "black",
                                left: tempTextValue?.style?.left ?? 0,
                                top: tempTextValue?.style?.top ?? 0,
                                shadow: {
                                    color: tempTextValue?.style?.shadow?.color ?? "transparent",
                                    blur: tempTextValue?.style?.shadow?.blur ?? 0,
                                    offsetX: tempTextValue?.style?.shadow?.offsetX ?? 0,
                                    offsetY: tempTextValue?.style?.shadow?.offsetY ?? 0,
                                }
                            });

                            // Re-render the canvas to apply the changes
                            canvas.renderAll();
                        }
                    }
                    else {
                        console.log("tempTextValue::", tempTextValue);
                        textDataSend = new fabric.Textbox(`${tempTextValue?.data}`, {
                            top: 0,
                            left: 0,
                            id: tempTextValue?.id,
                            fontSize: tempTextValue?.style?.fontSize ?? 16,
                            fontWeight: tempTextValue?.style?.fontWeight ?? "normal",
                            textAlign: tempTextValue?.style?.textAlign ?? "left",
                            fontStyle: tempTextValue?.style?.fontStyle ?? "normal",
                            underline: (tempTextValue?.style?.textDecoration == "underline") ? true : false,
                            fill: tempTextValue?.style?.color ?? "black",
                            left: tempTextValue?.style?.left ?? 0,
                            top: tempTextValue?.style?.top ?? 0,
                            shadow: {
                                color: tempTextValue?.style?.shadow?.color ?? "transparent",
                                blur: tempTextValue?.style?.shadow?.blur ?? 0,
                                offsetX: tempTextValue?.style?.shadow?.offsetX ?? 0,
                                offsetY: tempTextValue?.style?.shadow?.offsetY ?? 0,
                            }
                        });

                        const canvasCenterX = canvas.getWidth() / 2;
                        const canvasCenterY = canvas.getHeight() / 2;

                        textDataSend.set({
                            left: canvasCenterX - textDataSend.width / 2,
                            top: canvasCenterY - textDataSend.height / 2,
                            originX: 'left', // or 'center' if you want the center point to be the reference
                            originY: 'top'   // or 'center' for vertical alignment
                        });

                        textDataSend.set({
                            id: tempTextValue?.id
                        });

                        canvas.add(textDataSend);
                        canvas.renderAll();
                    }

                    canvas.on('selection:cleared', (e) => {
                        if (e) {
                            const data = {};
                            return props?.getDataValue(); // Pass the `tempTextValue`
                        } else {
                            // If the item is not selected
                            return props?.getDataValue(); // No parameters passed
                        }
                    });
                }
                else if (["image"].includes(tempTextValue?.type)) {
                    console.log("tempTextValue::", tempTextValue);

                    // Check if an image with the same ID already exists on the canvas
                    const existingImage = canvas.getObjects().find((obj) => obj?.id === tempTextValue?.id);

                    if (existingImage) {
                        // Update the properties of the existing image instance
                        existingImage.set({
                            left: tempTextValue?.style?.left ?? existingImage.left,
                            top: tempTextValue?.style?.top ?? existingImage.top,
                        });

                        // Re-render the canvas to apply changes
                        canvas.renderAll();
                    } else {
                        // Create a new image if it does not exist
                        const imageUrl = tempTextValue?.data;
                        const imgElement = new Image();
                        imgElement.crossOrigin = 'Anonymous'; // Handle CORS
                        imgElement.src = imageUrl;

                        imgElement.onload = () => {
                            const defaultWidth = 100;
                            const defaultHeight = 100;
                            const scaleX = defaultWidth / imgElement.width;
                            const scaleY = defaultHeight / imgElement.height;
                            const scale = Math.min(scaleX, scaleY);

                            let imgInstance = new fabric.Image(imgElement, {
                                scaleX: scale,
                                scaleY: scale,
                                id: tempTextValue?.id,
                                left: tempTextValue?.style?.left ?? 0,
                                top: tempTextValue?.style?.top ?? 0,
                            });

                            // Center the image only if no left and top styles are provided
                            if (!tempTextValue?.style?.left && !tempTextValue?.style?.top) {
                                const canvasCenterX = canvas.getWidth() / 2;
                                const canvasCenterY = canvas.getHeight() / 2;
                                imgInstance.set({
                                    left: canvasCenterX - (imgInstance.width * scale) / 2,
                                    top: canvasCenterY - (imgInstance.height * scale) / 2,
                                });
                            }

                            canvas.add(imgInstance);
                            canvas.setActiveObject(imgInstance);
                            canvas.renderAll();
                            updateCanvasData();

                            // Event listeners for the fabric.Image instance
                            imgInstance.on('modified', updateCanvasData);
                            imgInstance.on('scaling', updateCanvasData);

                            // Ensure the correct behavior when selection is cleared
                            canvas.on('before:selection:cleared', (e) => {
                                if (e.target === imgInstance) {
                                    updateCanvasData();
                                }
                            });
                        };

                        imgElement.onerror = () => {
                            console.error("Failed to load image:", imageUrl);
                        };
                    }

                    // Event to reset selection data if selection is cleared
                    canvas.on('selection:cleared', (e) => {
                        if (e) {
                            props?.getDataValue(); // Trigger the data reset function
                        }
                    });
                }
            })
        }
    };

    useEffect(() => {
        if (props?.canvasDeleteItem !== null) {
            deleteSelectedElement(props?.canvasDeleteItem);
        }
    }, [props?.canvasDeleteItem])

    const deleteSelectedElement = (id) => {
        if (canvas) {
            // const activeObject = canvas.getActiveObject();
            const activeObject = canvas.getObjects().find((obj) => obj?.id === id);
            if (activeObject) {
                canvas.remove(activeObject);
                canvas.discardActiveObject();
                canvas.renderAll();
                updateCanvasData();
            } else {
                console.log('No object selected');
            }
        }
    };

    const saveCanvasData = (index) => {
        if (canvas && index !== null) {
            const scale = 3;
            const jsonData = canvas.toJSON();
            const originalWidth = canvas.getWidth();
            const originalHeight = canvas.getHeight();

            // Temporarily adjust the canvas size for high resolution
            canvas.setWidth(originalWidth * scale);
            canvas.setHeight(originalHeight * scale);
            canvas.setZoom(scale);

            // Generate the base64 data URL
            const imgURL = canvas.toDataURL({ format: 'png', multiplier: scale, });

            // Restore original canvas size
            canvas.setWidth(originalWidth);
            canvas.setHeight(originalHeight);
            canvas.setZoom(1);

            // Update canvas list
            // console.log('imgURL:: ', imgURL)
            canvasData[index].imgURL = imgURL
            canvasData[index].data = jsonData
            console.log("From Save CanvasData::", canvasData);
            setCanvasData([...canvasData]);
        }
    };

    const updateCanvasData = () => {

        if (canvas && currentIndex !== null) {
            const scale = 3;
            const jsonData = canvas.toJSON();
            const originalWidth = canvas.getWidth();
            const originalHeight = canvas.getHeight();

            // Temporarily adjust the canvas size for high resolution
            canvas.setWidth(originalWidth * scale);
            canvas.setHeight(originalHeight * scale);
            canvas.setZoom(scale);

            // Generate the base64 data URL
            const imgURL = canvas.toDataURL({ format: 'png', multiplier: scale });

            // Restore original canvas size
            canvas.setWidth(originalWidth);
            canvas.setHeight(originalHeight);
            canvas.setZoom(1);

            // Ensure canvasData at currentIndex exists
            if (canvasData[currentIndex]) {
                canvasData[currentIndex].imgURL = imgURL;
                canvasData[currentIndex].data = jsonData;
                console.log("From Update CanvasData::", canvasData);
                setCanvasData([...canvasData]);
                // console.error("No canvas data available at the current index.");

                // Pass the updated canvas data back to the parent
                console.log("canvasData before updateCanvasData::", canvasData);
                props?.onSwitch({
                    data: canvasData,
                    activeCanvas: canvasData[currentIndex],
                    index: currentIndex
                });
            } else {
                console.error("No canvas data available at the current index.");
            }
        }
    };

    const onlyUpdateCanvasData = () => {
        if (canvas && currentIndex !== null) {
            // Get the current canvas state as JSON
            const jsonData = canvas.toJSON();

            // Generate a base64 data URL of the canvas
            const imgURL = canvas.toDataURL({ format: 'png' });

            // Ensure the canvasData array has an entry for the current index
            const updatedCanvasData = [...canvasData];
            updatedCanvasData[currentIndex] = {
                ...updatedCanvasData[currentIndex],
                imgURL: imgURL,
                data: jsonData,
            };

            // Update the canvasData state
            setCanvasData(updatedCanvasData);

            // Pass the updated canvas data back to the parent component
            props?.onSwitch({
                data: updatedCanvasData,
                activeCanvas: updatedCanvasData[currentIndex],
                index: currentIndex,
                tempNotChanges: true
            });
        } else {
            console.error("Canvas or currentIndex is invalid.");
        }
    };


    const manageMockupDetail = (obj = {}) => {

        const row = obj?.isPreview ? { ...currentMockupObj, ...obj } : obj;

        if (row?.preview) {
            const designData = canvasData?.find(e => e?.value == obj?.value)
            row.designData = designData ?? []

            setCurrentMockupObj({ ...row })
        }
    }


    const trackCanvasChanges = () => {
        if (!canvas) {
            console.error("Canvas is not initialized yet.");
            return;
        }

        // Event when an object is selected
        canvas.on('object:selected', (e) => {
            const selectedObject = e.target;
            logObjectData(selectedObject);
        });

        // Event when an object is modified (moved, scaled, etc.)
        canvas.on('object:modified', (e) => {
            const modifiedObject = e.target;
            logObjectData(modifiedObject);
            // onlyUpdateCanvasData();
        });

        canvas.on('object:rotating', (e) => {
            const modifiedObject = e.target;
            logObjectData(modifiedObject);
            // onlyUpdateCanvasData();
        });

        // Event when an object is clicked (mouse down)
        canvas.on('mouse:down', (e) => {
            const clickedObject = e.target;
            if (clickedObject) {
                logObjectData(clickedObject);
                // onlyUpdateCanvasData();
            }
        });

        canvas.on('mouse:up', (e) => {
            onlyUpdateCanvasData();
        });

        // Event when selection is cleared (no object is selected)
        canvas.on('selection:cleared', () => {
            console.log("Selection cleared. No object selected.");
            return props?.cleanSendText();
        });
    };

    trackCanvasChanges();

    useEffect(() => {
        console.log("canvasData::", canvasData);
    }, [canvasData])

    const logObjectData = (obj) => {
        if (obj) {
            if (obj.type === 'textbox' || obj.type === 'Textbox' || obj.type === 'text') {

                // For validate Text
                if (/^[\w\s.,?!'"]+$/.test(obj?.text)) {
                    // Text
                    const data = {
                        id: obj?.id,
                        type: "text",
                        from: "canvas",
                        data: obj?.text,
                        style: {
                            fontSize: obj.fontSize * obj.scaleX,
                            fontWeight: obj?.fontWeight,
                            textAlign: obj?.textAlign ?? "left",
                            color: obj?.fill,
                            textDecoration: obj?.underline ? "underline" : "none",
                            fontStyle: (obj?.fontStyle == "italic") ? "italic" : "normal",
                            left: obj?.left ?? 0,
                            top: obj?.top ?? 0,
                            shadow: {
                                color: obj?.shadow?.color,
                                offsetX: obj?.shadow?.offsetX,
                                offsetY: obj?.shadow?.offsetY,
                                blur: obj?.shadow?.blur
                            }
                        },
                    }
                    return props?.getDataValue({
                        data: data,
                        ratio: {
                            itemWidth: obj?.width * obj?.scaleX,
                            itemHeight: obj?.height * obj?.scaleY,
                            itemAngle: obj?.angle,
                            width: canvas.getWidth(),
                            height: canvas.getHeight()
                        },
                    });
                }
                // Otherwise clipart
                else {
                    // Clipart
                    const data = {
                        id: obj?.id,
                        data: obj?.text,
                        type: "clipart",
                        style: {
                            left: obj?.left ?? 0,
                            top: obj?.top ?? 0,
                        }
                    }

                    return props?.getDataValue({
                        data: data,
                        ratio: {
                            itemWidth: obj?.width * obj?.scaleX,
                            itemHeight: obj?.height * obj?.scaleY,
                            itemAngle: obj?.angle,
                            width: canvas.getWidth(),
                            height: canvas.getHeight()
                        },
                    });
                }
            }
            else {
                // For validate Image
                if (obj?.type == "image") {
                    const data = {
                        id: obj?.id,
                        data: obj?._element?.src,
                        type: "image",
                        style: {
                            left: obj?.left ?? 0,
                            top: obj?.top ?? 0,
                        }
                    }
                    console.log("image::", obj?._element?.src);
                    return props?.getDataValue({
                        data: data,
                        ratio: {
                            actualWidth: obj?.width,
                            actualHeight: obj?.height,
                            itemWidth: obj?.width * obj?.scaleX,
                            itemHeight: obj?.height * obj?.scaleY,
                            itemAngle: obj?.angle,
                            width: canvas.getWidth(),
                            height: canvas.getHeight()
                        },
                    });
                }
            }
        }
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Delete') {
                const activeObject = canvas.getActiveObject();
                if (activeObject) {
                    canvas.remove(activeObject);
                    canvas.renderAll();
                    updateCanvasData();
                    console.log("canvasData::", canvasData);
                }
            }
        };

        // Add event listener for keydown
        window.addEventListener('keydown', handleKeyDown);

        // Cleanup event listener when component unmounts
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [canvas, canvasData]);

    useEffect(() => {
        console.log("currentObject::", currentObject);
    }, [currentObject])

    return (
        <>
            <div>
                <div className='canvas-body-sides-label p-2 gap-2 flex-between-align'>
                    <div className='d-flex gap-2'>
                        {
                            canvasData?.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className={`canvas-body-sides-label-list px-2 py-1 text-capitalize ${(index === currentIndex ? 'active' : '')}`}
                                        onClick={() => switchCanvas({ current: currentIndex, next: index })}
                                    >
                                        {item?.label}
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div
                        className={`canvas-body-sides-label-list px-2 py-1 text-uppercase gap-2 flex-center-align`}
                        data-tooltip-id="my-tooltip"
                        data-tooltip-content="Preview"
                        onClick={() => {
                            setShow(true);
                            previewChange();
                        }}
                    >
                        <MdOutlineZoomOutMap className='fs-25' />
                    </div>
                    <Tooltip id="my-tooltip" place="left" />
                </div >
                <div className='canvas-body-sides-image mt-4 position-relative'>
                    <div className='canvas-bg-image position-absolute h-100 w-auto overflow-hidden' style={{ backgroundColor: props?.productColor }}>
                        {/* <img
                            src={SERVER_URL + canvasData[currentIndex]?.thumbnail}
                            className='h-100 w-100'
                        /> */}
                        <img
                            src={canvasData[currentIndex]?.thumbnail}
                            className='h-100 w-100'
                        />
                        <div
                            style={{
                                position: 'absolute',
                                border: '1px dotted black',
                                outline: '1px dotted white',
                                height: `${currentObject?.ratioData?.height / 5}%`,
                                width: `${currentObject?.ratioData?.width / 5}%`,
                                top: `${currentObject?.ratioData?.y / 5}%`,
                                left: `${currentObject?.ratioData?.x / 5}%`,
                                transform: `rotate(${currentObject?.ratioData?.rotation}deg)`
                            }}
                            ref={parentRef}
                        >
                            <canvas width="300" height="300" ref={canvaRef} />
                            <div
                                className={`position-absolute top-0 start-0 flex-center-align h-100 w-100 ${(!loader) ? "d-none" : ''}`}
                                style={{ backgroundColor: "rgba(255,255,255,0.5)" }}
                            >
                                <Spinner animation="border" variant="success" />
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            {/* <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', padding: '10px' }}>
                <button onClick={addElement}>Add Circle and Text</button>
                <button onClick={addElementOn}>Add Another</button>
                <button onClick={downloadHighResImage}>Download High-Res Image</button>
                <button onClick={deleteSelectedElement}>Delete Selected</button>
                <button onClick={exportToJSON}>Export as JSON</button>
            </div> */}
            <Modal
                show={show}
                onHide={() => setShow(false)}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Product Preview</Modal.Title>
                </Modal.Header>
                <Modal.Body className='custom-modal-body align-content-center'>
                    <Container>
                        <Row className='gy-3 gy-md-4'>
                            <Col className='col-12 col-lg-6 col-xl-5'>
                                <div className='bg-info w-100 aspect-1-1'>
                                    {
                                        [currentMockupObj]?.map((row, index) => {
                                            const imgData = canvasData?.find(e => e?.value == row?.value);
                                            return (
                                                <ProductPreviewImage
                                                    key={index}
                                                    colorCode={row?.code ?? '#ffffff'}
                                                    objectData={{
                                                        ratioData: imgData?.ratioData ?? {},
                                                        preview: row?.preview || null,
                                                        imgURL: imgData?.imgURL || null,
                                                    }}
                                                />
                                            )
                                        })
                                    }
                                </div>
                            </Col>
                            <Col className='col-12 col-lg-6 col-xl-6 d-grid gap-3'>
                                <div className='d-grid gap-2'>
                                    <h5 className='fs-20 m-0 fw-semibold'>Color Images</h5>
                                    <div className='d-flex overflow-auto flex-wrap py-2 gap-1'>
                                        {
                                            colorVariantData?.map((row, index) => <>
                                                <div
                                                    className={`pointer p-2 pb-1`}
                                                    onClick={() => manageMockupDetail(row)}
                                                >
                                                    <img
                                                        // src={SERVER_URL + row?.preview}
                                                        src={row?.preview}
                                                        style={{ backgroundColor: `${row?.code ?? '#ffffff'}` }}
                                                        // alt={canvasData?.productName + ' ' + row?.label}
                                                        className={`sm-preview-img rounded-1 border ${row?.code == currentMockupObj?.code ? 'border-2 border-dark' : ''}`}
                                                    />
                                                    <div
                                                        className='bg-transparent fs-12 fw-bold text-center text-capitalize h-25 pt-1'
                                                        style={{ color: '#808c98' }}
                                                    >
                                                        {row?.label}
                                                    </div>
                                                </div>
                                            </>)
                                        }
                                    </div>
                                </div>
                                <div>
                                    <h5 className='fs-20 m-0 fw-semibold'>Mockup Images</h5>
                                    <div className='d-flex flex-wrap py-2 gap-3 w-100'>
                                        {
                                            previewData?.product?.previewImagesData?.map((row, index) => {
                                                const imgData = canvasData?.find(e => e?.value == row?.value);
                                                return (
                                                    <div
                                                        key={index}
                                                        className={`sm-preview-img pointer rounded-1 border ${row?.preview == currentMockupObj?.preview ? 'border-2 border-dark' : ''}`}
                                                    >
                                                        <ProductPreviewImage
                                                            isList={true}
                                                            colorCode={currentMockupObj?.code ?? '#ffffff'}
                                                            objectData={{
                                                                ratioData: imgData?.ratioData ?? {},
                                                                preview: row?.preview || null,
                                                                imgURL: imgData?.imgURL || null,
                                                                value: imgData?.value,
                                                                label: imgData?.label,
                                                            }}
                                                            onPrevSelect={(e) => manageMockupDetail({ ...e, code: currentMockupObj?.code ?? '#ffffff', isPreview: true })}
                                                        />
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default EditCanvasComponent;
