import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { Button, Container, Dropdown, Modal } from 'react-bootstrap';
import Flatpickr from "react-flatpickr";
import { FaCheck } from 'react-icons/fa6';
import { GoDotFill } from 'react-icons/go';
import { IoMdClose } from 'react-icons/io';
import { translate } from 'react-range/lib/utils';

function FilterList({ filterList = {}, onChangeData, isDateFilter = false, groupStatus = '' }) {


    const tempDate = [
        {
            label: "Today",
            value: 0,
            type: "day"
        },
        {
            label: "Yesterday",
            value: 1,
            type: "day"
        },
        {
            label: "Last 7 days",
            value: 7,
            type: "day"
        },
        {
            label: "Last 30 days",
            value: 30,
            type: "day"
        },
        {
            label: "This month",
            value: -1, // -1 for current month, 0 for last month
            type: "month"
        },
        {
            label: "Custom range",
            value: 0,
            type: "custom"
        },
        // {
        //     label: "Last month",
        //     value: 2,
        //     type: "month"
        // },
        // {
        //     label: "Last 3 month",
        //     value: 3,
        //     type: "month"
        // },
        // {
        //     label: "Last 6 month",
        //     value: 6,
        //     type: "month"
        // },
        // {
        //     label: "Last 1 year",
        //     value: 12,
        //     type: "month"
        // },
    ]
    const [handleDate, setHandleDate] = useState(tempDate[3]);
    const [filters, setFilters] = useState({});
    const [showFilter, setShowFilter] = useState(false);

    const handleCheckOrder = (item) => {
        const tempData = filters[item?.title] ?? [];

        const newData = (tempData?.length > 0) ? tempData.find((e) => e == item?.value) : null;
        if (newData) {
            const check = filters[item?.title].filter((e) => e != item?.value);
            setFilters((prevData) => ({
                ...prevData,
                [item?.title]: check
            }))
        }
        else {
            if (item?.value == '') {
                const data = filterList?.parentData?.find((e) => e?.title == item?.title);
                if (data?.data?.length == filters[item?.title]?.length) {
                    setFilters((prevData) => ({
                        ...prevData,
                        [item?.title]: []
                    }));
                }
                else {
                    const check = data?.data.map((prevData) => (
                        prevData?.value
                    ));
                    setFilters((prevData) => ({
                        ...prevData,
                        [item?.title]: check
                    }));
                }
            }
            else {
                setFilters((prevData) => ({
                    ...prevData,
                    [item?.title]: [...tempData, item?.value]
                }))
            }
        }
    }

    const handleDateChange = (item) => {
        const { label, value, type } = item;
        const endDate = moment().format('YYYY-MM-DD');

        const startDate = type == "month"
            ? `${moment().subtract(0, "years").year()}-${moment().subtract(value, "months").month()}-01`
            : moment().subtract(value, type).format('YYYY-MM-DD');
        console.log("[startDate, endDate]::", [startDate, endDate]);

        setFilters((prevData) => ({
            ...prevData,
            dateRange: [startDate, endDate]
        }))
    }

    useEffect(() => {
        setFilters({
            ...filters,
            search: ''
        })
    }, [groupStatus])

    // useEffect(() => {
    //     handleDateChange(handleDate);
    // }, [])

    useEffect(() => {
        if (onChangeData) {
            return onChangeData(filters);
        }
    }, [filters])

    useEffect(() => {
        console.log("handleDate::", handleDate);
    }, [handleDate])

    useEffect(() => {
        console.log("[startDate, endDate]::", filterList?.parentData?.length);
    }, [])

    return (
        <>
            <div className='d-flex align-items-center gap-2 d-md-grid'>
                <Container fluid className='custom-seller-category-search px-0'>
                    <div className='d-flex align-items-center gap-2 gap-lg-3 px-2 px-lg-3'>
                        <label className='bi bi-search' htmlFor='text'></label>
                        <input
                            type="text"
                            className='py-2 fs-sm-14 pe-2'
                            id='text'
                            placeholder='Search by order ID'
                            value={filters?.search}
                            autoComplete='off'
                            onChange={(e) => {
                                setFilters((prevData) => ({
                                    ...prevData,
                                    search: e?.target?.value
                                }))
                                // setLoader(true);
                            }}
                        />
                    </div>
                </Container>
                <div className='d-md-none'
                    style={{ cursor: 'pointer' }}
                    onClick={() => setShowFilter(!showFilter)}
                ><i className="bi bi-funnel-fill px-3 py-2 seller-box"></i></div>


                {/* Filters */}
                <Modal
                    show={showFilter}
                    onHide={() => setShowFilter(!showFilter)}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Filter Invoices</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className='d-grid gap-3'>
                            {
                                (filterList?.parentData?.length > 0) &&
                                filterList?.parentData?.map((item, index) => {
                                    return (
                                        <div key={index} className={`d-grid w-100 custom-select-option`}>
                                            <Dropdown className='d-inline w-100' autoClose="outside">
                                                <Dropdown.Toggle variant="light" id="dropdown-autoclose-outside" className='seller-box no-border-rad text-start w-100 fw-semibold text-capitalize'>
                                                    {item.label}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className='w-100 gap-1 check-holder-free'>
                                                    {
                                                        item?.data.map((subItem, subIndex) => {
                                                            return (
                                                                <Dropdown.Item key={subIndex} as={'div'} className='flex-between-align gap-1 gap-md-2 justify-content-start w-100'>
                                                                    <input type="checkbox"
                                                                        className='ui-checkbox'
                                                                        name={subItem?.label}
                                                                        id={`${subItem?.label}-1`}
                                                                        value={subItem?.value}
                                                                        onChange={() => {
                                                                            handleCheckOrder({
                                                                                value: subItem?.value,
                                                                                title: item?.title
                                                                            })
                                                                            // setLoader(true);
                                                                        }} />

                                                                    <label htmlFor={`${subItem?.label}-1`} className='text-capitalize fs-18 fs-lg-14 fs-sm-12'>{subItem?.label}</label>
                                                                </Dropdown.Item>
                                                            )
                                                        })
                                                    }
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                    )
                                })
                            }
                            <div className={`d-grid w-100 custom-select-option`}>
                                <Dropdown className='d-inline w-100' autoClose="outside">
                                    <Dropdown.Toggle variant="light" id="dropdown-autoclose-outside" className='seller-box no-border-rad text-start w-100 fw-semibold text-capitalize'>
                                        Date Range
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='w-100 gap-1 check-holder-free'>
                                        {
                                            tempDate.map((subItem, subIndex) => {
                                                return (
                                                    <Dropdown.Item key={subIndex} as={'div'} className='flex-between-align gap-1 gap-md-2 justify-content-start w-100'>
                                                        <input type="checkbox"
                                                            className='ui-checkbox'
                                                            name={subItem?.label}
                                                            id={subItem?.label}
                                                            value={subItem?.value}
                                                            onChange={() => {
                                                                handleCheckOrder({
                                                                    value: subItem?.value,
                                                                    title: "dateRange"
                                                                })
                                                                // setLoader(true);
                                                            }} />

                                                        <label htmlFor={subItem?.label} className='text-capitalize fs-18 fs-lg-14 fs-sm-12'>{subItem?.label}</label>
                                                    </Dropdown.Item>
                                                )
                                            })
                                        }
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setShowFilter(false)}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={() => setShowFilter(false)}>Apply Filters</Button>
                    </Modal.Footer>
                </Modal>
            </div>
            <div className='d-none d-md-grid gap-2'>
                <div className='d-none d-md-flex align-items-center'>
                    {
                        (isDateFilter) &&
                        <>
                            <span className='fs-14 ps-2 ms-1'>Sort by&nbsp;&nbsp;</span>
                            {
                                (handleDate?.value == 0 && handleDate?.type == "custom") ?
                                    <div className={`d-grid ${(filterList?.parentData?.length == 0) ? "half-border-rad" : ""} custom-select-option custom-date-range-box position-relative`}
                                        style={{
                                            minWidth: (filterList?.parentData?.length == 1 || filterList?.parentData?.length == 0)
                                                ? '225px'
                                                : '100%',
                                        }}>
                                        <Flatpickr
                                            className="form-control border-0 fs-13 fs-lg-12"
                                            options={{
                                                mode: "range",
                                                dateFormat: "d, M Y"
                                            }}
                                            value={[
                                                new Date(filters?.dateRange[0]), // First date
                                                new Date(filters?.dateRange[1])
                                            ]}
                                            placeholder='select range for filter'
                                            onChange={(e) => {
                                                setFilters((prevData) => ({
                                                    ...prevData,
                                                    dateRange: []
                                                }));
                                                setFilters((prevData) => ({
                                                    ...prevData,
                                                    dateRange: [
                                                        e[0] ? moment(e[0]).format("YYYY-MM-DD") : prevData.dateRange[0], // Preserve the start date if no change
                                                        e[1] ? moment(e[1]).format("YYYY-MM-DD") : prevData.dateRange[1]  // Preserve the end date if no change
                                                    ]
                                                }));
                                            }}
                                        />
                                        <div
                                            className='pointer fw-bold position-absolute top-50'
                                            onClick={() => {
                                                handleDateChange(tempDate[3])
                                                setHandleDate(tempDate[3])
                                            }}
                                            style={{
                                                right: "5px",
                                                transform: "translateY(-50%)"
                                            }}
                                        >
                                            <IoMdClose />
                                        </div>
                                    </div>
                                    :
                                    <div className={`d-grid custom-select-option-without-height`}
                                        style={{
                                            minWidth: (filterList?.parentData?.length == 1 || filterList?.parentData?.length == 0)
                                                ? '200px'
                                                : '100%',
                                        }}>
                                        <Dropdown className='d-inline w-100' autoClose="outside">
                                            <Dropdown.Toggle variant="light" id="dropdown-autoclose-outside" className='custom-hover-remove-btn seller-box no-border-rad text-start w-100 text-capitalize'>
                                                <div className='d-flex align-items-center gap-2 fs-13'>
                                                    {/* <GoDotFill className='color-temp' /> */}
                                                    {handleDate?.label}
                                                </div>
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className='w-100 gap-1 check-holder-free'>
                                                {
                                                    tempDate.map((subItem, subIndex) => {
                                                        return (
                                                            <Dropdown.Item key={subIndex} as={'div'} className='flex-between-align gap-1 gap-md-2 p-0 justify-content-between w-100'>
                                                                <label htmlFor={subItem?.label} className='text-capitalize fs-13 fs-sm-12 py-1 ps-3 w-100'>
                                                                    {subItem?.label}
                                                                </label>
                                                                {
                                                                    (handleDate?.label == subItem?.label) &&
                                                                    <FaCheck className='color-info me-3' />
                                                                }
                                                                <input type="checkbox"
                                                                    className='ui-checkbox d-none'
                                                                    name={subItem?.label}
                                                                    id={subItem?.label}
                                                                    value={subItem?.value}
                                                                    onChange={() => {
                                                                        if (subItem?.type == "custom") {
                                                                            setFilters((prevData) => ({
                                                                                ...prevData,
                                                                                dateRange: [moment().subtract(7, 'days').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
                                                                            }))
                                                                            setHandleDate(subItem);
                                                                        } else {
                                                                            handleDateChange(subItem)
                                                                            setHandleDate(subItem);
                                                                        }
                                                                    }}
                                                                    checked={(handleDate?.value == subItem?.value) && handleDate?.type == subItem?.type}
                                                                />
                                                            </Dropdown.Item>
                                                        )
                                                    })
                                                }
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </div>
                            }
                        </>
                    }
                    {
                        (filterList?.parentData?.length > 0) &&
                        filterList?.parentData?.map((item, index) => {
                            return (
                                <div key={index} className={`d-grid ${(filterList?.parentData?.length == 1 || filterList?.parentData?.length == 0) ? 'w-25' : 'w-100'} custom-select-option`}>
                                    <Dropdown className='d-inline w-100' autoClose="outside">
                                        <Dropdown.Toggle variant="light" id="dropdown-autoclose-outside" className='seller-box no-border-rad text-start w-100 fw-semibold text-capitalize'>
                                            {item.label}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className='w-100 gap-1 check-holder-free'>
                                            {
                                                item?.data.map((subItem, subIndex) => {
                                                    return (
                                                        <Dropdown.Item key={subIndex} as={'div'} className='flex-between-align gap-1 gap-md-2 justify-content-start w-100'>
                                                            <input type="checkbox"
                                                                className='ui-checkbox'
                                                                name={subItem?.label}
                                                                id={subItem?.label}
                                                                value={subItem?.value}
                                                                onChange={() => {
                                                                    handleCheckOrder({
                                                                        value: subItem?.value,
                                                                        title: item?.title
                                                                    })
                                                                    // setLoader(true);
                                                                }} />

                                                            <label htmlFor={subItem?.label} className='text-capitalize fs-18 fs-lg-14 fs-sm-12'>{subItem?.label}</label>
                                                        </Dropdown.Item>
                                                    )
                                                })
                                            }
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </>
    )
}

export default FilterList