import React, { useEffect } from 'react'
import { Col, Container, Placeholder, Row } from 'react-bootstrap'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import { frontEnd_API, siteConfig } from '../../Config/Config'
import { storeUser } from '../../Store/Slices/UserSlice'
import FormComponent from '../../Components/FormComponent'
import { pattern } from '../../Config/Constant'
import { ToastContainer } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import { addDomain } from '../../Store/Slices/DomainSlice'
import { PiStarFourFill } from "react-icons/pi";
import { CiAt } from "react-icons/ci";
import { IoDiamondOutline } from "react-icons/io5";

import tShirtImg from '../../Assets/Images/tshirt.png'
import cupImg from '../../Assets/Images/cup.png'
import hoodieImg from '../../Assets/Images/hoodie.png'
import socksImg from '../../Assets/Images/socks.png'

function SigninPage() {
    const { domain } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const localDomain = useSelector((state) => state.domain.value);

    useEffect(() => {
        if (location.pathname.startsWith('/from-home')) {
            navigate('/signin');
            console.log(domain);
            if (domain) {
                dispatch(addDomain(domain))
            }
        }
    }, [domain, location, navigate, dispatch])

    document.title = `Seller Sign in | ${siteConfig?.name}`;

    return (
        <div>
            <Container fluid className='d-grid align-items-center custom-signin-page'>
                <Row className='custom-signin-page-row'>
                    <Col className='col-12 col-md-6 d-grid p-0'>
                        <div className='p-4 p-md-5 custom-signin-page-img align-content-between align-content-md-end'>
                            <div className='custom-img-dark-color' />
                            <div className='custom-bg-dark-color' />
                            <div className='flex-center-align d-flex d-md-none'>
                                <a href={"https://printfuse.in/"} className="logo-holder p-1">
                                    <img src={require('../../Assets/Images/printfuse blue.png')} alt="" />
                                </a>
                            </div>
                            <div className='d-grid gap-4 content-holder'>
                                <h5 className='m-0 fw-semibold fs-sm-16 fs-md-18 '>
                                    “Design and sell 200+ print on demand products hasslefree. Benefit from the most competitive prices and maximize your profits.”
                                </h5>
                            </div>
                        </div>
                    </Col>
                    <Col className='col-12 col-md-6 d-grid p-0'>
                        <div className='px-3 px-lg-5 py-5 custom-signin-page-form position-relative'>
                            <div className='flex-center-align start-50 d-none d-md-flex position-absolute'
                                style={{
                                    transform: "translate(-50%, 100%)",
                                }}>
                                <a href={"https://printfuse.in/"} className="logo-holder p-1">
                                    <img src={require('../../Assets/Images/printfuse blue.png')} alt="" />
                                </a>
                            </div>
                            <Container className='cs-container-sm d-grid gap-4 px-xl-5 custom-signin-content'>
                                <div className='custom-sticky-icons d-none d-md-block'>
                                    <img src={tShirtImg} className='first-icon min-img-h-50px' />
                                    <img src={cupImg} className='second-icon min-img-h-50px' />
                                    <img src={hoodieImg} className='third-icon min-img-h-50px' />
                                    <img src={socksImg} className='fourth-icon min-img-h-50px' />
                                    {/* <CiAt className='first-icon' /> */}
                                    {/* <PiStarFourFill className='second-icon' />
                                    <IoDiamondOutline className='third-icon' />
                                    <PiStarFourFill className='fourth-icon' /> */}
                                </div>
                                <h1 className='fw-bold m-0 text-center'>Hello Again !</h1>

                                {/*
                                <div className='flex-center-align px-sm-5 gap-2 btn-list'>
                                    <button className='git-btn'><i className="bi bi-github"></i></button>
                                    <button className='google-btn'><i className="bi bi-google"></i></button>
                                </div>
                                <span className='text-center p-color'>OR</span>
                                */}

                                <div className='d-grid px-md-5'>
                                    <FormComponent
                                        action={frontEnd_API.signin}
                                        dispatchRedux={storeUser}
                                        submitFullWidth={true}
                                        submitBTNAlign={'custom-submit-btn'}
                                        loadPage={true}
                                        button={'Log in'}
                                        loadFirst={localDomain ? true : false}
                                        formMessage={"Login Successfully !"}
                                        isForgetPassword={true}
                                        navigatePageURL={(localDomain) && '/app/create-store'}
                                        fields={[
                                            {
                                                divClass: 'col-12',
                                                name: 'email',
                                                key: 'email',
                                                required: true,
                                                pattern: pattern.email,
                                                placeholder: "Your Email"
                                            },
                                            {
                                                divClass: 'col-12',
                                                name: 'Password',
                                                key: 'password',
                                                type: 'password',
                                                required: true,
                                                placeholder: "Password"
                                            }
                                        ]}
                                    />
                                </div>
                                <div className='d-grid gap-3 pt-sm-3 pt-4'>
                                    <span className='fs-16 text-center'>
                                        Have no account yet? <Link className='text-decoration-none color-temp fw-semibold' to={'/signup'}>Sign up</Link>
                                    </span>
                                </div>
                            </Container>
                        </div>
                    </Col>
                </Row>
            </Container>
            <ToastContainer />
        </div>
    )
}

export default SigninPage