import React, { useEffect, useState } from 'react'
import moment from 'moment';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap'
import { getPaginationData } from '../Helpers/fun_helpers';
import { Dropdown } from 'react-bootstrap';

function CommonPagination(props = {}) {
    const [pagination, setPagination] = useState();
    const [filterObj, setFilterObj] = useState({
        limit: 10,
        page: 1,
        sellerId: 0,
        sellerId: 0,
        storeId: 0,
        orderBy: 'id-DESC',
        search: '',
        dateRangeValue: 6,
        dateRange: [moment().subtract(6, 'months').toDate(), moment().toDate()],
    })

    const limitList = [
        {
            label: "10",
            value: 10
        },
        {
            label: "25",
            value: 25
        },
        {
            label: "50",
            value: 50
        },
        {
            label: "100",
            value: 100
        },
        {
            label: "250",
            value: 250
        },
    ]

    useEffect(() => {
        const pageArray = getPaginationData(filterObj?.page, props?.totalCount, filterObj?.limit)
        setPagination([...pageArray]);
    }, [filterObj, props?.totalCount])

    useEffect(() => {
        if (props?.changePage) {
            props?.changePage(filterObj)
        }
    }, [filterObj])

    return (
        <div className='flex-between-align common-pagination-style'>
            <Pagination className="col-sm m-0" listClassName="d-flex align-items-center">
                {
                    pagination?.map((row, index) =>
                        <>
                            <PaginationItem key={index} disabled={row?.disabled} onClick={(e) => setFilterObj((prev) => ({ ...prev, page: Number(row?.value) }))}>
                                <PaginationLink className='fs-sm-12'>{row?.label}</PaginationLink>
                            </PaginationItem>
                        </>
                    )
                }
            </Pagination>
            <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic" className='fs-sm-12 custom-bg-temp'>
                    {filterObj?.limit}
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    {
                        limitList.map((item, index) => (
                            <Dropdown.Item key={index} className='fs-sm-12' onClick={() => setFilterObj((prevData) => ({
                                ...prevData,
                                page: 1,
                                limit: item?.value
                            }))}>{item?.label}</Dropdown.Item>
                        ))
                    }
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}

export default CommonPagination