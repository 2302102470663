import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { frontEnd_API, header } from '../Config/Config';
import moment from 'moment';

function OrderSubCategoryList(props) {
    const params = useParams();
    const [handleCat, setHandleCat] = useState(props?.listData?.length > 0 && props?.listData[0]?.value);

    useEffect(() => {
        if (params?.orderStatus) {
            console.log("params?.orderStatus::", params?.orderStatus);
        }
    }, [props?.onTabHandleChange])

    useEffect(() => {
        setHandleCat(props?.listData?.length > 0 && props?.listData[0]?.value)
        console.log("params?.groupStatus::", params?.groupStatus);
    }, [params?.groupStatus])

    useEffect(() => {
        if (props?.onCategoryChange && handleCat) {
            return props?.onCategoryChange(handleCat);
        }
    }, [handleCat])

    const exportToExcel = async () => {

        const exportHeading = ['Sr No', 'Order ID', 'Order Date', 'Customer Detail', '	Order Value', 'Provider', 'Payment Mode', 'Status']
        // console.log(props?.handleFilters);
        try {
            const { data } = await axios?.put(frontEnd_API?.orders, { ...props?.handleFilters, isExport: true }, header)
            const newData = await data?.data?.map((e, index) => ({
                key: index + 1,
                id: e?.orderNumber,
                orderDate: e?.orderDate ? moment(e?.orderDate).format('DD, MMM YYYY') : moment().format('DD, MMM YYYY'),
                name: e?.userData?.name ?? "-",
                value: e?.orderNetAmount ?? "-",
                provider: e?.providerData?.businessName ?? "-",
                orderPaymentMode: e?.orderPaymentMode ?? "-",
                orderStatus: e?.orderStatus ?? "-"
            }))?.map(e => Object?.values(e))

            const workBook = XLSX.utils.book_new()

            const workSheet = XLSX.utils.json_to_sheet([...newData])

            XLSX.utils.book_append_sheet(workBook, workSheet, ['ORDER'])
            XLSX.utils.sheet_add_aoa(workSheet, [exportHeading], { origin: "A1" })

            XLSX.writeFile(workBook, `ORDER-EXCEL-REPORT-${moment().format('DD-MM-YYYY-HH-mm-ss')}.xlsx`)
        } catch (error) {
            console.log("error::", error);
        }
    }

    return (
        <div className='main-custom-order-sub-category flex-between-align w-100'>
            <div className='d-flex custom-order-sub-category'>
                {
                    (props?.listData?.length > 0) &&
                    props?.listData.map((item, index) => (
                        <span
                            key={index}
                            className={`pointer text-center text-capitalize px-2 px-sm-3 py-1 fs-14 fs-md-12 fs-sm-10 ${(item?.value == handleCat) ? "active" : ""}${item?.value == 'ndr' ? ' bg-warning text-dark' : ''}`}
                            onClick={() => setHandleCat(item?.value)}
                            title={item?.label}
                        >
                            {(item?.label == "dispatch") ? "dispatched" : (item?.label == "on way") ? "In-transit" : (["cancelled"]?.includes(item?.label)? 'Provider Cancelled': item?.label)}
                        </span>
                    ))
                }
            </div>
            {
                (["complete", "cancel"].includes(params?.groupStatus)) &&
                <Button variant='success' className='custom-bg-temp' onClick={exportToExcel}>Export to Excel</Button>
            }
        </div>
    )
}

export default OrderSubCategoryList