import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { frontEnd_API, header, siteConfig } from '../../Config/Config';
import { Link, useNavigate } from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import { Col, Container, Row, Spinner, Tab } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { IoStorefrontOutline } from "react-icons/io5";
import { SiReacthookform } from "react-icons/si";
import { MdKeyboardDoubleArrowLeft } from "react-icons/md";
import { sellerSocialLinks } from '../../Data/localData';
import { useDispatch, useSelector } from 'react-redux';
import { updateUser } from '../../Store/Slices/UserSlice';
import { CiLocationOn } from "react-icons/ci";
import { pattern } from '../../Config/Constant';
import { addStore } from '../../Store/Slices/StoreSlice';
import { removeDomain } from '../../Store/Slices/DomainSlice';
import Loader from '../../Components/Loader';
import Swal from 'sweetalert2';

function SellerCreateStore() {

    const [selectedOption, setSelectedOption] = useState(0);
    const [selectedChoices, setSelectedChoices] = useState([]);
    const [selectedRadio, setSelectedRadio] = useState('');
    const [selectedSocial, setSelectedSocial] = useState([]);
    const [selectedFollowers, setSelectedFollowers] = useState('');
    const [stateDataShop, setStateDataShop] = useState([]);
    const [cityDataShop, setCityDataShop] = useState([]);
    const [stateData, setStateData] = useState([]);
    const [cityData, setCityData] = useState([]);
    const [loader, setLoader] = useState(false);
    const localDomain = useSelector((state) => state.domain.value);
    const userData = useSelector((state) => state.user.value);
    const dispatch = useDispatch();

    useEffect(() => {
        if (localDomain) {
            const domainWithoutSuffix = localDomain.replace(/\.printfuse\.in$/, "");
            setStoreData((prevData) => ({
                ...prevData,
                'name': domainWithoutSuffix,
                'domain': localDomain
            }))
        }
    }, [localDomain])

    const choices = [
        "Designing Products",
        "Performance Marketing",
        "Content Creation",
        "Search Engine Optimisation",
        "I'm just starting"
    ];

    const socialFollowers = [
        'Below 50K',
        '50K to 250K',
        '250K to 500K',
        '500K to 1M',
        '1M to 10M',
        'Over 10M',
    ]

    const social = [
        'Facebook or Instagram',
        'YouTube',
        'Google Search',
        'Email',
        'Friends or Family',
        'Nothing from above applies to me'
    ]

    useEffect(() => {
        if (userData?.storeLimit != 0 && userData?.totalStore >= userData?.storeLimit) {
            Swal.fire({
                title: `Store limit reached!`,
                text: `Upgrade to Pro plan to create more stores & get upto 30% discount on products.`,
                icon: 'error',
                allowOutsideClick: false,
                allowEscapeKey: false,
                showCancelButton: true,
                confirmButtonColor: '#12715B',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Subscribe',
                cancelButtonText: 'No, Thanks',
            }).then(async (result) => {
                if (result.isConfirmed) {
                    // If "Subscribe" is clicked, navigate to subscription page
                    navigate("/subscription");
                } else if (result.dismiss === Swal.DismissReason.cancel) {
                    // If "No, Thanks" is clicked, navigate to home page "/"
                    navigate("/");
                }
            });
        }
        console.log("userData::", userData);
    }, [])

    // Handle checkboxes value
    const handleCheckboxChange = (event) => {
        const { value, checked } = event.target;
        setSelectedChoices((prevValues) => {
            if (checked) {
                return [...prevValues, value];
            } else {
                return prevValues.filter((item) => item !== value);
            }
        });
    };

    const handleSocialCheckboxChange = (event) => {
        const { value, checked } = event.target;
        setSelectedSocial((prevValues) => {
            if (checked) {
                return [...prevValues, value];
            } else {
                return prevValues.filter((item) => item !== value);
            }
        });
    };

    // For API call remove options and social link from here...
    // const options = identityData;
    const sellerSocial = sellerSocialLinks;
    // const social = socialLinks;

    // Remove comment for API call
    const [options, setOptions] = useState([]);
    useEffect(() => {

        // Get Identity data from API
        axios.get(frontEnd_API.getIdentity, header)
            .then((res) => {
                setOptions(res?.data?.data);
                console.log(res?.data?.data);

            })
            .catch((e) => {
                console.log(e);
            })

        axios.get(frontEnd_API.getGlobal, header)
            .then((res) => {
                setStateDataShop(res?.data?.data);
                setStateData(res?.data?.data);
                console.log("res::", res);
            })
            .catch((e) => {
                console.log("Err::", e);
            })

    }, [])


    const [storeData, setStoreData] = useState()

    const [tabChange, setTabChange] = useState('identity');
    const [err, setErr] = useState({});

    useEffect(() => {
        setStoreData((prevData) => ({
            ...prevData,
            identityId: selectedOption.value,
            experienceTags: selectedChoices,
            hearFrom: selectedRadio,
            socialMediaTags: selectedSocial,
            socialFollowers: selectedFollowers
        }))
    }, [selectedOption, selectedChoices, selectedRadio, selectedSocial, selectedFollowers])

    // const [newLogo, setNewLogo] = useState(null);

    const handleInputChange = (val) => {

        delete err[val?.name];
        console.log("err::", err);

        // For Chacking Required value
        if (val?.pattern || val?.required) {
            if (val?.required && val?.value?.length <= 0) {
                err[val?.name] = `${val?.name} is required`
            }

            if (val?.pattern && val?.value?.length > 0) {
                const patternCheck = new RegExp(val?.pattern);
                if (!patternCheck.test(val?.value))
                    err[val?.name] = `Invalid pattern for ${val?.name}`;
            }
        }

        const { name, value } = val;
        setStoreData((prevData) => ({
            ...prevData,
            [name]: value
        }))

        // if (val?.file) {
        //     const fileType = val?.file?.type;
        //     const allowedTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/webp', 'image/svg+xml'];

        //     if (!allowedTypes.includes(fileType)) {
        //         console.log(fileType);
        //         setErr({ logo: 'Only PNG, JPG, JPEG, WEBP, and SVG files are allowed.' });
        //         setNewLogo(null);
        //         setStoreData({ ...storeData, logo: null });
        //     } else {
        //         setNewLogo(val.value);
        //         setStoreData({ ...storeData, logo: val?.file });
        //     }
        // }
    }

    const manageSameAddress = (obj = {}) => {
        if (obj?.value !== storeData[obj?.compareKey]) {
            setIsChecked(false)
        }
    }

    const [domainAvailable, setDomainAvailable] = useState();
    const [domainOption, setDomainOption] = useState([]);

    const handleDomainCheck = async (val) => {
        const { value, name } = val;

        try {
            const { data } = await axios.put(frontEnd_API.checkDomain, { domain: value }, header)
            delete err['name'];
            setErr({ ...err })
            setDomainAvailable(data?.message)
            storeDomainValue(data?.data?.domain);
            setDomainOption([])
        } catch (e) {
            const res = e?.response?.data
            err.name = res?.data?.domain || res?.data?.name
            setErr({ ...err })
            setDomainOption(res?.data?.suggestionDomain ?? [])
        }

        setStoreData((prevData) => ({
            ...prevData,
            [name]: value,
        }))
    }

    const storeDomainValue = (val) => {
        setStoreData((prevData) => ({
            ...prevData,
            'domain': val
        }))
    }

    const [isChecked, setIsChecked] = useState(false);

    const handleAddressChange = () => {
        if (
            storeData?.addressFirstLine &&
            storeData?.addressSecondLine &&
            storeData?.state &&
            storeData?.city &&
            storeData?.pinCode &&
            storeData?.mobileNumber &&
            storeData?.emailAddress
        ) {
            const check = stateData.find((item) => item.value === storeData?.state)
            if (check) (
                setCityData(check?.children)
            )
            setIsChecked(!isChecked);
            if (!isChecked) {
                setStoreData((prevData) => ({
                    ...prevData,
                    billingAddressFirstLine: storeData?.addressFirstLine,
                    billingAddressSecondLine: storeData?.addressSecondLine,
                    billingState: storeData?.state,
                    billingCity: storeData?.city,
                    billingPinCode: storeData?.pinCode,
                    billingMobileNumber: storeData?.mobileNumber,
                    billingEmailAddress: storeData?.emailAddress
                }))
            }
            else {
                setStoreData((prevData) => ({
                    ...prevData,
                    billingAddressFirstLine: '',
                    billingAddressSecondLine: '',
                    billingState: '',
                    billingCity: '',
                    billingPinCode: '',
                    billingMobileNumber: '',
                    billingEmailAddress: ''
                }))
            }
        } else {
            toast("Fill Shop Address First !")
            setIsChecked(false);
        }

    }

    const handleStateChangeShop = (val) => {
        const check = stateDataShop?.find((e) => e.value === val);
        console.log(check);
        setCityDataShop(check?.children)
    }

    const handleStateChange = (val) => {
        const check = stateData?.find((e) => e.value === val);
        console.log(check);
        setCityData(check?.children)
    }

    useEffect(() => {
        console.log(storeData);
        if (storeData?.billingAddressFirstLine ||
            storeData?.billingAddressSecondLine ||
            storeData?.billingState ||
            storeData?.billingCity ||
            storeData?.billingPinCode ||
            storeData?.billingMobileNumber ||
            storeData?.billingEmailAddress
        ) {
            if (
                storeData?.billingAddressFirstLine === storeData?.addressFirstLine &&
                storeData?.billingAddressSecondLine === storeData?.addressSecondLine &&
                storeData?.billingState === storeData?.state &&
                storeData?.billingCity === storeData?.city &&
                storeData?.billingPinCode === storeData?.pinCode &&
                storeData?.billingMobileNumber === storeData?.mobileNumber &&
                storeData?.billingEmailAddress === storeData?.emailAddress
            ) {
                delete err?.billingAddressFirstLine;
                delete err?.billingAddressSecondLine;
                delete err?.billingState;
                delete err?.billingCity;
                delete err?.billingPinCode;
                delete err?.billingMobileNumber;
                delete err?.billingEmailAddress;
                setIsChecked(true);
            }
            else {
                setIsChecked(false);
            }
        }
    }, [storeData])

    const navigate = useNavigate();

    const submitStoreData = () => {
        if (Object.values(err).length === 0) {
            // Add Country in data
            setLoader(true)
            const addCountry = {
                ...storeData,
                country: 'India',
                billingCountry: 'India'
            };
            axios.post(frontEnd_API.postStore, addCountry, header)
                .then((res) => {
                    console.log("res::", res);
                    setLoader(false)
                    dispatch(updateUser({
                        role: 'SELLER',
                        storeCode: res?.data?.data?.storeCode,
                        storeId: res?.data?.data?.id,
                        store: res?.data?.data
                    }));
                    dispatch(addStore(res?.data?.data))
                    setStoreData({});
                    setErr({});
                    toast('Form submitted successfully!');
                    dispatch(updateUser({ totalStore: res?.data?.data?.totalStore }));
                    navigate('/dashboard');
                    dispatch(removeDomain());
                    window.location.reload();
                })
                .catch((e) => {
                    const res = e?.response?.data
                    setLoader(false)

                    console.log("error::", e);

                    if (res?.data?.non_field_error) {
                        // alert(res?.message);
                        // Swal.fire(
                        //     `Request failed`,
                        //     `${e?.response?.data?.message}`,
                        //     'error'
                        // );
                        Swal.fire({
                            title: `Store limit reached!`,
                            text: `${e?.response?.data?.message}`,
                            icon: 'error',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            showCancelButton: true,
                            confirmButtonColor: '#12715B',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Subscribe',
                            cancelButtonText: 'No, Thanks',
                        }).then(async (result) => {
                            if (result.isConfirmed) {
                                navigate("/subscription");
                            } else if (result.dismiss === Swal.DismissReason.cancel) {
                                navigate("/");
                            }
                        });
                    } else {
                        setErr(e?.response?.data?.data)
                        toast(e?.response?.data?.message)
                    }
                })
        }

    }

    const chunkArray = (array, chunkSize) => {
        const chunks = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            chunks.push(array.slice(i, i + chunkSize));
        }
        return chunks;
    };

    const chunkedSellerSocial = chunkArray(sellerSocial, 4);

    document.title = `Create Store | ${siteConfig?.name}`;

    return (
        <div className='custom-create-store-tab py-3 px-2 px-sm-4 p-sm-4 flex-center-align'>

            <div className='seller-box custom-card p-3 px-3 px-sm-5 py-sm-4 cs-lg-width d-grid'>
                <Tab.Container activeKey={tabChange}>
                    <Nav fill variant="pills" onSelect={(selectedKey) => setTabChange(selectedKey)}>
                        <Nav.Item>
                            <Nav.Link eventKey="identity" className='flex-center-align gap-1'>
                                <span className='custom-triangle'></span>
                                <span>Identity</span>
                                <MdKeyboardDoubleArrowRight />
                            </Nav.Link>
                        </Nav.Item>
                        {
                            (selectedOption.label === 'content creator') &&
                            <>
                                <Nav.Item>
                                    <Nav.Link eventKey="social-media" className='flex-center-align gap-1' disabled={!selectedOption}>
                                        <span className='custom-triangle'></span>
                                        <span>Social Media</span><MdKeyboardDoubleArrowRight /></Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="followers" className='flex-center-align gap-1' disabled={!selectedSocial.length}>
                                        <span className='custom-triangle'></span>
                                        <span>Followers</span><MdKeyboardDoubleArrowRight /></Nav.Link>
                                </Nav.Item>
                            </>
                        }
                        <Nav.Item>
                            <Nav.Link eventKey="experience" className='flex-center-align gap-1' disabled={(!selectedOption) ? true : (selectedOption.label === "content creator" && (!selectedFollowers || !selectedSocial.length))}>
                                <span className='custom-triangle'></span>
                                <span>Experience</span><MdKeyboardDoubleArrowRight /></Nav.Link>
                        </Nav.Item>
                        <Nav.Item>

                            <Nav.Link eventKey="referral" className='flex-center-align gap-1' disabled={(selectedOption.label === "content creator") ? (!selectedChoices.length || !selectedSocial.length) : (!selectedChoices.length)}>
                                <span className='custom-triangle'></span>
                                <span>Referral</span><MdKeyboardDoubleArrowRight /></Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link eventKey="store-detail" disabled={(selectedOption.label === "content creator") ? (!selectedSocial.length || !selectedRadio || !selectedChoices.length) : (!selectedChoices.length || !selectedRadio)}>
                                Store Details
                            </Nav.Link>
                            {/* <Nav.Link eventKey="store-detail">
                                Store Details
                            </Nav.Link> */}
                        </Nav.Item>
                    </Nav>
                    <Tab.Content>
                        <Tab.Pane eventKey="identity" className='py-4'>
                            <div className='custom-contain-align-center py-4'>
                                <div className="custom-identity-container container">
                                    <h3 className='fw-semibold fs-sm-20'>What best describes you?</h3>
                                    <p className='m-0 fs-sm-12'>Choose the option that best describes your current experience and situation.</p>
                                    <div className='flex-center-align'>
                                        <Row className="py-3 py-sm-4 flex-center-align g-4">
                                            {options.map((option) => (
                                                <Col className='col-12 col-sm-4 col-md-3 d-grid'>
                                                    <div
                                                        key={option.id}
                                                        className={`option px-3 ${selectedOption.value === option.value ? 'selected' : ''}`}
                                                        onClick={() => setSelectedOption(option)}
                                                    >
                                                        <h6 className='text-capitalize fw-semibold fs-16 m-0'>{option.label}</h6>
                                                        {option.shortDescription && <p className='m-0 py-1 py-sm-2'>{option.shortDescription}</p>}
                                                    </div></Col>
                                            ))}
                                        </Row>
                                    </div>
                                    <div className='flex-center-align'>
                                        <Link
                                            className={`next-button text-decoration-none fw-bold d-grid${!selectedOption ? 'custom-btn-disabled' : ''}`}
                                            onClick={() => selectedOption.label === "content creator" ? setTabChange('social-media') : setTabChange('experience')}
                                            style={{ pointerEvents: !selectedOption ? 'none' : 'auto' }}
                                        >
                                            <button type="button" className={`button ${!selectedOption ? 'custom-btn-disabled' : 'active-btn'}`}>
                                                <span className="button__text">Next</span>
                                                <span className="button__icon">
                                                    <MdKeyboardDoubleArrowRight className="svg" />
                                                </span>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="social-media" className='py-4'>
                            <div className='custom-contain-align-center py-4'>
                                <div className="custom-identity-container container">
                                    <h3 className='fw-semibold fs-sm-20'>Which social media do you use?</h3>
                                    <p className='m-0 fs-sm-12'>Select which social media platforms you currently use.</p>
                                    <div className="d-grid d-sm-flex justify-content-center fs-sm-14 gap-3 py-5">
                                        {chunkedSellerSocial.map((chunk, chunkIndex) => (
                                            <div key={chunkIndex} className='d-grid align-content-start gap-3'>
                                                {chunk.map((item, index) => (
                                                    <>
                                                        <div key={index} className='custom-checkbox custom-check-border px-3 py-1 d-flex gap-2 justify-content-start align-items-start align-items-sm-center text-start'>
                                                            <input type="checkbox" id={item.name} value={item.name} onChange={handleSocialCheckboxChange} />
                                                            <span className='bi bi-check'></span>
                                                            <label htmlFor={item.name} style={{ cursor: 'pointer' }} className='text-capitalize'>{item.name}</label>
                                                        </div>
                                                    </>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                    <div className='flex-center-align'>
                                        <Link className='back-button text-decoration-none fw-bold flex-center-align' onClick={() => setTabChange('identity')}><MdKeyboardDoubleArrowLeft />Back</Link>
                                        <Link
                                            className={`next-button text-decoration-none fw-bold d-grid${!selectedSocial.length ? 'custom-btn-disabled' : ''}`}
                                            onClick={() => selectedSocial.length && setTabChange('followers')}
                                            style={{ pointerEvents: !selectedSocial.length ? 'none' : 'auto' }}
                                        >
                                            <button type="button" className={`button ${!selectedSocial.length ? 'custom-btn-disabled' : 'active-btn'}`}>
                                                <span className="button__text">Next</span>
                                                <span className="button__icon">
                                                    <MdKeyboardDoubleArrowRight className="svg" />
                                                </span>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="followers" className='py-4'>
                            <div className='custom-contain-align-center py-4'>
                                <div className="custom-identity-container container">
                                    <h3 className='fw-semibold fs-sm-20'>How many social media followers do you have?</h3>
                                    <p className='m-0 fs-sm-12 fs-14'>This allows us to recommend optimal products and sales channels based on your number of followers.</p>
                                    <div className="d-grid justify-content-center gap-1 gap-md-2 gap-lg-3 fs-sm-14 gap-2 py-4">
                                        {socialFollowers.map((item, index) => {
                                            return (
                                                <div key={index} className='custom-check-border px-3 py-1 custom-radio d-flex gap-2 justify-content-start align-items-start align-items-sm-center text-start'>
                                                    <label htmlFor={item} className='d-flex align-items-center gap-2 text-capitalize'>
                                                        <input type="radio" id={item} name='followers' onChange={() => setSelectedFollowers(item)} />
                                                        {item}
                                                    </label>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className='flex-center-align'>
                                        <Link className='back-button text-decoration-none fw-bold flex-center-align' onClick={() => setTabChange('social-media')}><MdKeyboardDoubleArrowLeft />Back</Link>
                                        <Link
                                            className={`next-button text-decoration-none fw-bold d-grid${(!selectedFollowers) ? 'custom-btn-disabled' : ''}`}
                                            onClick={() => selectedFollowers && setTabChange('experience')}
                                            style={{ pointerEvents: (!selectedFollowers) ? 'none' : 'auto' }}
                                        >
                                            <button type="button" className={`button ${(!selectedFollowers) ? 'custom-btn-disabled' : 'active-btn'}`}>
                                                <span className="button__text">Next</span>
                                                <span className="button__icon">
                                                    <MdKeyboardDoubleArrowRight className="svg" />
                                                </span>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="experience" className='py-4'>
                            <div className='custom-contain-align-center py-4'>
                                <div className="custom-identity-container container">
                                    <h3 className='fw-semibold fs-sm-20'>What areas do you have experience in?</h3>
                                    <p className='fs-sm-14'>Choose any specific skills you already have experience working with.</p>
                                    <div className="d-grid justify-content-center fs-sm-14 gap-2 pb-5 pt-3">
                                        {choices.map((item, index) => {
                                            return (
                                                <div key={index} className='custom-check-border px-3 py-1 custom-checkbox d-flex gap-2 justify-content-start align-items-start align-items-sm-center text-start'>
                                                    <input type="checkbox" id={item} value={item} onChange={handleCheckboxChange} />
                                                    <span className='bi bi-check'></span>
                                                    <label htmlFor={item}>{item}</label>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className='flex-center-align'>
                                        <Link className='back-button text-decoration-none fw-bold flex-center-align' onClick={() => setTabChange((selectedFollowers && selectedOption.label === "content creator") ? 'followers' : 'identity')}><MdKeyboardDoubleArrowLeft />Back</Link>
                                        <Link
                                            className={`next-button text-decoration-none fw-bold d-grid${!selectedChoices.length ? 'custom-btn-disabled' : ''}`}
                                            onClick={() => selectedChoices.length && setTabChange('referral')}
                                            style={{ pointerEvents: !selectedChoices.length ? 'none' : 'auto' }}
                                        >
                                            <button type="button" className={`button ${!selectedChoices.length ? 'custom-btn-disabled' : 'active-btn'}`}>
                                                <span className="button__text">Next</span>
                                                <span className="button__icon">
                                                    <MdKeyboardDoubleArrowRight className="svg" />
                                                </span>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="referral" className='py-4'>
                            <div className='custom-contain-align-center py-4'>
                                <div className="custom-identity-container container">
                                    <h3 className='fw-semibold fs-sm-20'>How did you hear about us?</h3>
                                    <p className='fs-sm-14'>Please select one option from below</p>
                                    <div className="d-grid justify-content-center fs-sm-14 gap-2 pb-5 pt-3">
                                        {social.map((item, index) => {
                                            return (
                                                <div key={index} className='custom-check-border px-3 py-1 custom-radio d-flex gap-2 justify-content-start align-items-start align-items-sm-center text-start'>
                                                    <label htmlFor={index} className='d-flex align-items-center gap-2'>
                                                        <input type="radio" id={index} name='social' onChange={() => setSelectedRadio(item)} />
                                                        {item}
                                                    </label>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className='flex-center-align'>
                                        <Link className='back-button text-decoration-none fw-bold flex-center-align' onClick={() => setTabChange('experience')}><MdKeyboardDoubleArrowLeft />Back</Link>
                                        <Link
                                            className={`next-button text-decoration-none fw-bold d-grid${!selectedRadio ? 'custom-btn-disabled' : ''}`}
                                            onClick={() => selectedRadio && setTabChange('store-detail')}
                                            style={{ pointerEvents: !selectedRadio ? 'none' : 'auto' }}
                                        >
                                            <button type="button" className={`button ${!selectedRadio ? 'custom-btn-disabled' : 'active-btn'}`}>
                                                <span className="button__text">Next</span>
                                                <span className="button__icon">
                                                    <MdKeyboardDoubleArrowRight className="svg" />
                                                </span>
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="store-detail" className='py-4'>
                            <Container className='custom-store-detail container d-grid gap-4 gap-sm-5'>
                                <Row className='custom-contain-align-center py-3'>
                                    <h3 className='fw-semibold text-center py-3'>Shop Details</h3>
                                    <Col className='col-0 d-none d-md-block col-md-3 custom-store-image'>
                                        <IoStorefrontOutline />
                                    </Col>
                                    <Col className='col-12 col-md-9 d-grid gap-1 gap-sm-2 gap-lg-3 custom-store-detail-content'>
                                        <div className='d-grid'>
                                            <label htmlFor="name">Shop Name</label>
                                            <input
                                                type="text"
                                                value={storeData?.name}
                                                name='name'
                                                id='name'
                                                className={(err?.name && !storeData?.name) && 'border-red'}
                                                onBlur={(e) => {
                                                    const { name, value } = e.target;
                                                    handleDomainCheck({ name, value });
                                                }}
                                                onChange={(e) => {
                                                    const { name, value } = e.target;
                                                    setDomainAvailable()
                                                    handleInputChange({ name: 'domain', value: '' });
                                                    handleInputChange({ name, value });
                                                }}
                                                required={true}
                                                autoComplete='off' />
                                            <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height flex-between-align'>
                                                {
                                                    err?.name &&
                                                    <span className='w-100 d-flex gap-1 pt-1'><i className="bi bi-x-lg" />{err?.name}</span>
                                                }
                                                {
                                                    (domainAvailable && !err?.name) &&
                                                    <span className='w-100 text-success d-flex gap-1 pt-1'><i className="bi bi-check-lg" />{domainAvailable}</span>
                                                }
                                                <div className='d-flex justify-content-end'>
                                                    <div className='fs-10 p-color'>{(storeData?.name?.length) ?? '0'}/20</div>
                                                </div>
                                            </span>
                                        </div>

                                        {
                                            (domainOption.length > 0) &&
                                            <div className='d-grid bg-secondary-subtle p-3 gap-2'>
                                                <span className='fs-14'>Suggested store names :</span>
                                                <div className='d-grid gap-1'>
                                                    {
                                                        domainOption.map((item, index) => {
                                                            return (
                                                                <div
                                                                    key={index}
                                                                    className='d-flex gap-2 fs-14'
                                                                    onClick={() => {
                                                                        storeDomainValue(item?.value)
                                                                        handleDomainCheck({
                                                                            name: 'name',
                                                                            value: item?.label
                                                                        })
                                                                    }}
                                                                    style={{ cursor: 'pointer' }}
                                                                >
                                                                    <span className='fw-semibold'>{item?.label}</span>
                                                                    <i className="bi bi-copy" />
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        }
                                        {
                                            (!err.name && storeData?.domain?.length > 0) &&
                                            <div className='d-grid bg-secondary-subtle p-3 gap-2'>
                                                <span className='fs-14'>store URL preview :</span>
                                                <span className='fw-semibold fs-14'>{storeData?.domain}</span>
                                            </div>
                                        }
                                        <div className='d-grid'>
                                            <label htmlFor="shortDescription">Description <span className='fs-12'>(Optional)</span></label>
                                            <textarea
                                                name="shortDescription"
                                                id="shortDescription"
                                                rows={2}
                                                value={storeData?.shortDescription}
                                                onChange={(e) => handleInputChange({ name: e.target.name, value: e.target.value })}
                                                autoComplete='off'>
                                            </textarea>
                                            <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height'>
                                            </span>
                                        </div>

                                        {/* Logo Selection */}
                                        {/* <div className='d-grid custom-img-selector'>
                                            <label htmlFor="logo">
                                                <span>Store Logo</span>
                                                {
                                                    (storeData?.logo)
                                                        ? <div>
                                                            <img src={
                                                                newLogo
                                                                    ? URL.createObjectURL(newLogo)
                                                                    : storeData.logo
                                                            } alt="" />
                                                        </div>
                                                        : <div>
                                                            <i className="bi bi-image fs-40" />
                                                        </div>
                                                }
                                            </label>
                                            <input
                                                type="file"
                                                // value={storeData?.logo}
                                                name='logo'
                                                id='logo'
                                                className={(err?.logo && !storeData?.logo) && 'border-red'}
                                                onChange={(e) => {
                                                    handleInputChange({
                                                        name: e.target.name,
                                                        file: e.target.files[0],
                                                    });
                                                    setNewLogo(e.target.files[0])
                                                }}
                                                required={true}
                                                accept=".png, .jpg, .jpeg, .webp, .svg"
                                                autoComplete='off' />
                                            <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height flex-between-align'>{err?.logo}</span>
                                        </div> */}
                                    </Col>
                                </Row>
                                <Row className='custom-contain-align-center py-3'>
                                    <h3 className='fw-semibold text-center py-3 py-sm-4'>Shop Address Details</h3>
                                    <Col className='d-none d-md-block col-md-3 custom-store-image'>
                                        <CiLocationOn className='mt-4' />
                                    </Col>
                                    <Col className='col-12 col-md-9 custom-store-detail-content d-grid gap-3'>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6 d-grid'>
                                                <label htmlFor="addressFirstLine">Address First Line</label>
                                                <textarea
                                                    name="addressFirstLine"
                                                    id="addressFirstLine"
                                                    rows={2}
                                                    className={(err?.addressFirstLine && !storeData?.addressFirstLine) && 'border-red'}
                                                    value={storeData?.addressFirstLine}
                                                    autoComplete='off'
                                                    onChange={(e) => handleInputChange({ name: e.target.name, value: e.target.value })}
                                                    placeholder="Ex. 'Shop no., Building name'" ></textarea>
                                                <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height'>{(!storeData?.addressFirstLine) && err?.addressFirstLine}</span>
                                            </div>
                                            <div className='col-12 col-sm-6 d-grid'>
                                                <label htmlFor="addressSecondLine">Address Second Line</label>
                                                <textarea
                                                    name="addressSecondLine"
                                                    id="addressSecondLine"
                                                    rows={2}
                                                    className={(err?.addressSecondLine && !storeData?.addressSecondLine) && 'border-red'}
                                                    value={storeData?.addressSecondLine}
                                                    autoComplete='off'
                                                    onChange={(e) => handleInputChange({ name: e.target.name, value: e.target.value })}
                                                    placeholder="Ex. 'Landmark'"></textarea>
                                                <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height'>{(!storeData?.addressSecondLine) && err?.addressSecondLine}</span>
                                            </div>
                                        </div>
                                        <div className='row d-flex'>
                                            <div className='col-6 col-sm-4 custom-over-hidden d-grid'>
                                                <label htmlFor="state">State</label>
                                                <select
                                                    value={storeData?.state}
                                                    name='state'
                                                    id='state'
                                                    className={(err?.state && !storeData?.state) && 'border-red'}
                                                    autoComplete='off'
                                                    onChange={(e) => {
                                                        handleStateChangeShop(e.target.value)
                                                        handleInputChange({ name: e.target.name, value: e.target.value })
                                                    }}>
                                                    <option value={0}>Select</option>
                                                    {
                                                        stateDataShop.map((item, index) => {
                                                            return (
                                                                <option key={index}>{item?.label}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                                <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height'>{(!storeData?.state) && err?.state}</span>
                                            </div>
                                            <div className='col-6 col-sm-4 custom-over-hidden d-grid'>
                                                <label htmlFor="city">City</label>
                                                <select
                                                    value={storeData?.city}
                                                    name='city'
                                                    id='city'
                                                    className={(err?.city && !storeData?.city) && 'border-red'}
                                                    autoComplete='off'
                                                    onChange={(e) => handleInputChange({
                                                        name: e.target.name, value: e.target.value
                                                    })}>
                                                    <option value={0}>Select</option>
                                                    {
                                                        cityDataShop.map((item, index) => {
                                                            return (
                                                                <option key={index}>{item?.label}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                                <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height'>{(!storeData?.city) && err?.city}</span>
                                            </div>
                                            <div className='col-12 col-sm-4 custom-over-hidden d-grid'>
                                                <label htmlFor="pinCode">Pincode</label>
                                                <input
                                                    type="number"
                                                    value={storeData?.pinCode}
                                                    name='pinCode'
                                                    id='pinCode'
                                                    className={(err?.pinCode && !storeData?.pinCode) && 'border-red'}
                                                    autoComplete='off'
                                                    onChange={(e) => handleInputChange({
                                                        name: e.target.name,
                                                        value: e.target.value,
                                                        pattern: pattern.pinCode
                                                    })} />
                                                <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height'>{err?.pinCode}</span>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6 d-grid'>
                                                <label htmlFor="mobileNumber">Mobile</label>
                                                <input
                                                    type="number"
                                                    value={storeData?.mobileNumber}
                                                    name='mobileNumber'
                                                    id='mobileNumber'
                                                    className={(err?.mobileNumber && !storeData?.mobileNumber) && 'border-red'}
                                                    autoComplete='off'
                                                    onChange={(e) => handleInputChange({
                                                        name: e.target.name,
                                                        value: e.target.value,
                                                        pattern: pattern.mobile
                                                    })} />
                                                <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height'>{err?.mobileNumber}</span>
                                            </div>
                                            <div className='col-12 col-sm-6 d-grid'>
                                                <label htmlFor="emailAddress">Email</label>
                                                <input
                                                    type="email"
                                                    value={storeData?.emailAddress}
                                                    name='emailAddress'
                                                    id='emailAddress'
                                                    className={(err?.emailAddress && !storeData?.emailAddress) && 'border-red'}
                                                    autoComplete='off'
                                                    onChange={(e) => handleInputChange({
                                                        name: e.target.name,
                                                        value: e.target.value,
                                                        pattern: pattern.email
                                                    })} />
                                                <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height'>{err?.emailAddress}</span>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='custom-contain-align-center py-3'>
                                    <h3 className='fw-semibold text-center py-3 py-sm-4'>Billing Address Details</h3>
                                    <Col className='d-none d-md-block col-md-3 custom-store-image'>
                                        <SiReacthookform className='mt-4' />
                                    </Col>
                                    <Col className='col-12 col-md-9 custom-store-detail-content d-grid gap-3'>
                                        <div className='row'>
                                            <div className='col-12 pb-3'>
                                                <div className='d-flex py-2 gap-2 align-items-center check-holder'>
                                                    <input type="checkbox" id='same' autoComplete='off' className='ui-checkbox' style={{ width: '20px', height: '20px', cursor: 'pointer' }}
                                                        onChange={handleAddressChange}
                                                        checked={isChecked}
                                                    />
                                                    <label htmlFor='same' className='fs-20 fw-semibold' style={{ cursor: 'pointer' }}>Same as shop address</label>
                                                </div>
                                            </div>
                                            <div className='col-12 col-sm-6 d-grid'>
                                                <label htmlFor="billingAddressFirstLine">Address First Line</label>
                                                <textarea
                                                    name="billingAddressFirstLine"
                                                    id="billingAddressFirstLine"
                                                    rows={2}
                                                    className={(err?.billingAddressFirstLine && !storeData?.billingAddressFirstLine) && 'border-red'}
                                                    value={storeData?.billingAddressFirstLine}
                                                    autoComplete='off'
                                                    onChange={(e) => {
                                                        handleInputChange({ name: e.target.name, value: e.target.value });
                                                        manageSameAddress({ value: e.target.value, compareKey: 'addressFirstLine' })
                                                    }}
                                                    placeholder="Ex. 'Shop no., Building name'" ></textarea>
                                                <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height'>{(!storeData?.billingAddressFirstLine) && err?.billingAddressFirstLine}</span>
                                            </div>
                                            <div className='col-12 col-sm-6 d-grid'>
                                                <label htmlFor="billingAddressSecondLine">Address Second Line</label>
                                                <textarea
                                                    name="billingAddressSecondLine"
                                                    id="billingAddressSecondLine"
                                                    rows={2}
                                                    className={(err?.billingAddressSecondLine && !storeData?.billingAddressSecondLine) && 'border-red'}
                                                    value={storeData?.billingAddressSecondLine}
                                                    autoComplete='off'
                                                    onChange={(e) => {
                                                        handleInputChange({ name: e.target.name, value: e.target.value })
                                                        manageSameAddress({ value: e.target.value, compareKey: 'addressSecondLine' })
                                                    }}
                                                    placeholder="Ex. 'Landmark'"></textarea>
                                                <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height'>{(!storeData?.billingAddressSecondLine) && err?.billingAddressSecondLine}</span>
                                            </div>
                                        </div>
                                        <div className='row d-flex'>
                                            <div className='col-6 col-sm-4 custom-over-hidden d-grid'>
                                                <label htmlFor="billingState">State</label>
                                                <select
                                                    value={storeData?.billingState}
                                                    name='billingState'
                                                    id='billingState'
                                                    className={(err?.billingState && !storeData?.billingState) && 'border-red'}
                                                    autoComplete='off'
                                                    onChange={(e) => {
                                                        handleStateChange(e.target.value)
                                                        handleInputChange({ name: e.target.name, value: e.target.value })
                                                        manageSameAddress({ value: e.target.value, compareKey: 'state' })
                                                    }}>
                                                    <option value={0}>Select</option>
                                                    {
                                                        stateData.map((item, index) => {
                                                            return (
                                                                <option key={index}>{item?.label}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                                <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height'>{(!storeData?.billingState) && err?.billingState}</span>
                                            </div>
                                            <div className='col-6 col-sm-4 custom-over-hidden d-grid'>
                                                <label htmlFor="billingCity">City</label>
                                                <select
                                                    value={storeData?.billingCity}
                                                    name='billingCity'
                                                    id='billingCity'
                                                    className={(err?.billingCity && !storeData?.billingCity) && 'border-red'}
                                                    autoComplete='off'
                                                    onChange={(e) => {
                                                        handleInputChange({
                                                            name: e.target.name,
                                                            value: e.target.value
                                                        })
                                                        manageSameAddress({ value: e.target.value, compareKey: 'city' })
                                                    }}>
                                                    <option value={0}>Select</option>
                                                    {
                                                        cityData.map((item, index) => {
                                                            return (
                                                                <option key={index}>{item?.label}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                                <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height'>{(!storeData?.billingCity) && err?.billingCity}</span>
                                            </div>
                                            <div className='col-12 col-sm-4 custom-over-hidden d-grid'>
                                                <label htmlFor="billingPinCode">Pincode</label>
                                                <input
                                                    type="number"
                                                    value={storeData?.billingPinCode}
                                                    name='billingPinCode'
                                                    id='billingPinCode'
                                                    className={(err?.billingPinCode && !storeData?.billingPinCode) && 'border-red'}
                                                    autoComplete='off'
                                                    onChange={(e) => {
                                                        handleInputChange({
                                                            name: e.target.name,
                                                            value: e.target.value,
                                                            pattern: pattern.pinCode
                                                        })
                                                        manageSameAddress({ value: e.target.value, compareKey: 'pinCode' })
                                                    }} />
                                                <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height'>{(!storeData?.billingPinCode) && err?.billingPinCode}</span>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-12 col-sm-6 d-grid'>
                                                <label htmlFor="billingMobileNumber">Mobile</label>
                                                <input
                                                    type="number"
                                                    value={storeData?.billingMobileNumber}
                                                    name='billingMobileNumber'
                                                    id='billingMobileNumber'
                                                    className={(err?.billingMobileNumber && !storeData?.billingMobileNumber) && 'border-red'}
                                                    autoComplete='off'
                                                    onChange={(e) => {
                                                        handleInputChange({
                                                            name: e.target.name,
                                                            value: e.target.value,
                                                            pattern: pattern.mobile
                                                        })
                                                        manageSameAddress({ value: e.target.value, compareKey: 'mobileNumber' })
                                                    }} />
                                                <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height'>{(!storeData?.billingMobileNumber) && err?.billingMobileNumber}</span>
                                            </div>
                                            <div className='col-12 col-sm-6 d-grid'>
                                                <label htmlFor="billingEmailAddress">Billing Email</label>
                                                <input
                                                    type="email"
                                                    value={storeData?.billingEmailAddress}
                                                    name='billingEmailAddress'
                                                    id='billingEmailAddress'
                                                    className={(err?.billingEmailAddress && !storeData?.billingEmailAddress) && 'border-red'}
                                                    autoComplete='off'
                                                    onChange={(e) => {
                                                        handleInputChange({
                                                            name: e.target.name,
                                                            value: e.target.value,
                                                            pattern: pattern.email
                                                        })
                                                        manageSameAddress({ value: e.target.value, compareKey: 'emailAddress' })
                                                    }} />
                                                <span className='fs-12 text-danger fw-bold fs-sm-10 custom-span-height'>{(!storeData?.billingEmailAddress) && err?.billingEmailAddress}</span>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <div className='d-flex justify-content-center pt-3 pt-md-4'>
                                    <Link className='back-button text-decoration-none fw-bold flex-center-align' onClick={() => setTabChange('referral')}><MdKeyboardDoubleArrowLeft />Back</Link>
                                    <Link
                                        className={`next-button text-decoration-none fw-bold d-grid`}
                                        onClick={submitStoreData}
                                        style={{ pointerEvents: (Object.values(err).length > 0) ? 'none' : 'auto', opacity: (Object.values(err).length > 0) ? '.1' : '1' }}
                                    >
                                        <button type="button" className={`button active-btn`}>
                                            <span className="button__text fs-14">
                                                Submit
                                            </span>
                                            <span className="button__icon">
                                                <MdKeyboardDoubleArrowRight className="svg" />
                                            </span>
                                        </button>
                                    </Link>
                                </div>
                            </Container>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div >

            <div className={`custom-loader ${(loader) ? 'd-flex' : 'd-none'}`}>
                <div className='aspect-5-3'>
                    <Loader />
                </div>
            </div>

        </div >
    )
}

export default SellerCreateStore