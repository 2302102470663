import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';

function ErrorPage() {
    const navigate = useNavigate();
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const updateInterval = 100; // Update progress every 100ms
        const totalDuration = 10000; // Total duration in milliseconds (10 seconds)
        const increment = 100 / (totalDuration / updateInterval); // Calculate the increment value for each update

        const timer = setTimeout(() => {
            navigate('/');
        }, totalDuration);

        const progressInterval = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress >= 100) {
                    clearInterval(progressInterval);
                    return 100;
                }
                return prevProgress + increment;
            });
        }, updateInterval);

        return () => {
            clearTimeout(timer);
            clearInterval(progressInterval);
        };
    }, [navigate]);

    return (
        <div className='custom-wrong-url'>
            <Container className='text-center fw-semibold flex-center-align gap-3 custom-img-holder'>
                <img src={require('../Assets/Images/Not1.png')} alt="Error" />
            </Container>
            <Container className='d-grid pb-4 pb-sm-5'>
                <div className='flex-center-align'>
                    <Link to={'/'}>Click Here or Wait for {((100 - progress) / 10).toFixed(0)}</Link>
                </div>
            </Container>
        </div>
    );
}

export default ErrorPage;
